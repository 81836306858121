import React from "react";
import { Link } from "react-router-dom";
import Button from "./ui/Button";
import Footer from "./Footer";

const IntroPage = () => {
  return (
    <div
      style={{
        backgroundImage:
          "url(https://sapient-dev-bucket-projects.s3.ap-south-1.amazonaws.com/dental-web-app/uploads/26137192963309872087.Microsite_Banner01-1-1.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div className="p-10 md:p-5 flex items-center justify-center gap-20 h-screen lg:overflow-y-auto text-[#383838]">
        <div className="flex items-center justify-center gap-20 w-[80%] 1xl:w-full lg:flex-col-reverse lg:py-10 lg:gap-6">
          <div className="max-w-[700px] 1xl:pr-10 lg:pr-0 lg:max-w-full">
            <h1 className="font-playfair text-[40px] md:text-[30px] mb-5 font-medium lg:text-center leading-[40px] md:leading-[34px]">
              What is your unique caregiving style?
            </h1>
            <div className="font-poppins lg:text-center text-[18px] sm:text-[14px]">
              <p>
                Through our research in caregiving, we’ve created a quiz that
                encompasses both unique and common characteristics of
                caregivers. By answering a few questions about your own
                experience, we will provide a unique caregiver profile and
                personalized visual tile, representing your one-of-a-kind
                caregiving style (whether you have been one or not) and also see
                this become a part of our global community of caregivers!
              </p>
              <p className="mt-2">
                Across geographies, age, abilities, gender, race, and beyond, we
                are connected by a deep commonality: that we care about those we
                love.
              </p>
              <p className="mt-2 font-semibold mb-5">Who cares? We all do.</p>
              <Link to="/form">
                <Button title="Lets find out!" />
              </Link>
            </div>
          </div>
          {/* <div className="w-1/4 flex justify-center 1xl:pr-20 lg:pr-0 lg:mt-20 xs:mt-40 xxs:mt-72">
            <IntroLogo />
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IntroPage;
